import axios from 'axios'

export default {
  getAll() {
    const url = `${process.env.VUE_APP_API_BASE_URL}/api/passes/owner`
    return axios.get(url)
  },
  add(pass) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/api/passes/owner`
    return axios.post(url, pass)
  },
  delete(id) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/api/passes/owner/${id}`
    return axios.delete(url)
  },
}