import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth.js'
import snackbar from '@/store/modules/snackbar.js'
import progressBar from '@/store/modules/progressBar.js'
import applications from '@/store/modules/applications.js'
import fares from '@/store/modules/fares.js'
import passes from '@/store/modules/passes.js'
import statements from '@/store/modules/statements.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    snackbar,
    progressBar,
    applications,
    fares,
    passes,
    statements,
  }
})
