import statementsApi from '@/api/statements.js'
import moment from 'moment'

const state = {
  items: [],
  dateFrom: moment().startOf('month').toDate(),
  dateTo: moment().endOf('month').toDate(),
}

const mutations = {
  SET_STATEMENTS(state, statements) {
    state.items = statements
  },
}

const actions = {
  fetchStatements({ state, commit }) {
    statementsApi.getAll(state.dateFrom, state.dateTo).then(r => commit('SET_STATEMENTS', r.data))
  },
  filterStatements({ commit }, payload) {
    statementsApi.getAll(payload.dateFrom, payload.dateTo).then(r => commit('SET_STATEMENTS', r.data))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}