import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const LoginApp = () => import(/* webpackChunkName: "login" */ '@/components/LoginApp.vue')

const MainApp = () => import(/* webpackChunkName: "apartments" */ '@/components/MainApp.vue')
const Applications = () => import(/* webpackChunkName: "apartments" */ '@/components/Applications/Applications.vue')
const Passes = () => import(/* webpackChunkName: "apartments" */ '@/components/Passes/Passes.vue')
const Statements = () => import(/* webpackChunkName: "apartments" */ '@/components/Statements/Statements.vue')

const router = new VueRouter({
  mode: 'history',
  routes: [
    { name: 'login', path: '/login', component: LoginApp },
    {
      name: 'app',
      path: '/app',
      component: MainApp,
      meta: { requiresAuth: true },
      children: [
        { name: 'applications', path: 'applications', component: Applications },
        { name: 'passes', path: 'passes', component: Passes },
        { name: 'statements', path: 'statements', component: Statements },
        { path: '', redirect: { name: 'applications' } }
      ]
    },
    {
      path: '', redirect: { name: 'app' },
    },
  ]
})

router.beforeEach((to, _from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth) {
      const token = localStorage.getItem('token');
      if (token) {
          next();
      } else {
          next({ name: 'login' });
      }
  } else {
      next();
  }
})

export default router