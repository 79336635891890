import axios from 'axios'

export default {
  getAll() {
    const url = `${process.env.VUE_APP_API_BASE_URL}/api/applications`
    return axios.get(url)
  },

  approve(id) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/api/applications/${id}/approve`
    return axios.post(url)
  },

  decline(id) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/api/applications/${id}/decline`
    return axios.post(url)
  },
}