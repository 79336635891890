import applicationsApi from '@/api/applications.js'

const state = {
  items: [],
}

const mutations = {
  SET_APPLICATIONS(state, applications) {
    state.items = applications
  },
}

const actions = {
  fetchApplications({ commit }) {
    applicationsApi.getAll().then(r => commit('SET_APPLICATIONS', r.data))
  },

  approve({ dispatch }, id) {
    applicationsApi.approve(id).then(() => {
      dispatch('fetchApplications')
    })
  },

  decline({ dispatch }, id) {
    applicationsApi.decline(id).then(() => {
      dispatch('fetchApplications')
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}