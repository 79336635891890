<template>
  <v-snackbar
    v-model="snackbarVisible"
    :timeout="3000"
    :color="snackbarColor"
    bottom
  >
    {{ snackbarMsg }}
    <v-btn text @click="snackbarVisible = false">Закрыть</v-btn>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      snackbarVisible: false,
    };
  },
  computed: mapState({
    showSnackbar: (state) => state.snackbar.show,
    snackbarMsg: (state) => state.snackbar.message,
    snackbarColor: (state) => state.snackbar.color,
  }),
  watch: {
    snackbarVisible: function (newVal) {
      if (newVal === false) {
        this.closeAlert();
      }
    },
    showSnackbar: function (newVal) {
      this.snackbarVisible = newVal;
    },
  },
  methods: {
    ...mapActions(["closeAlert"]),
  },
};
</script>