/* eslint-disable no-unused-vars */

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from '@/router'
import store from '@/store/index'
import axios from 'axios'
import index from '@/filters/index'

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  created() {
    const token = localStorage.getItem('token');
    if (token) {
      this.$store.commit('SET_TOKEN', token);
    }
    axios.interceptors.request.use(
      request => {
        this.$store.dispatch('startLoading');
        return request;
      },
      error => {
        this.$store.dispatch('stopLoading', true);
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      response => {
        this.$store.dispatch('stopLoading');
        if (response.config.method !== "get") {
          this.$store.dispatch('showAlert', { message: "Результат: успешно" });
        }
        return response;
      },
      error => {
        this.$store.dispatch('stopLoading', true);
        if (error.response.status === 400) {
          this.$store.dispatch('showAlert', { message: error.response.data.Message });
        }
        if (error.response.status === 401) {
          this.$store.dispatch('logout');
        }
        return Promise.reject(error);
      }
    )
  },
  render: h => h(App)
}).$mount('#app')
