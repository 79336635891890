import faresApi from '@/api/fares.js'

const state = {
  items: [],
}

const mutations = {
  SET_FARES(state, fares) {
    state.items = fares
  },
}

const actions = {
  fetchFares({ commit }) {
    faresApi.getAll().then(r => commit('SET_FARES', r.data))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}