import passesApi from '@/api/passes'

const state = {
  passes: [],
}

const mutations = {
  SET_PASSES: (state, passes) => state.passes = passes,
}

const actions = {
  fetchPasses: ({ commit }) => passesApi.getAll().then(r => commit('SET_PASSES', r.data)),
  addPass: ({ dispatch }, pass) => passesApi.add(pass).then(() => dispatch('fetchPasses')),
  deletePass: ({ dispatch }, id) => passesApi.delete(id).then(() => dispatch('fetchPasses')),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}