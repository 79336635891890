<template>
  <div>
    <router-view />
    <Snackbar />
  </div>
</template>

<script>
import Snackbar from "./components/Snackbar";

export default {
  components: { Snackbar },
  name: "App",
};
</script>